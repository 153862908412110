export const weapons = [
  'Ares',
  'Bucky',
  'Bulldog',
  'Classic',
  'Frenzy',
  'Ghost',
  'Guardian',
  'Judge',
  'Marshal',
  'Melee',
  'Odin',
  'Operator',
  'Phantom',
  'Sheriff',
  'Shorty',
  'Spectre',
  'Stinger',
  'Vandal',
]

export default weapons

export const weaponSkinExceptions: string[] = [
  'Prime Guardian',
  'Tethered Realms Guardian',
  'Sovereign Guardian',
  'Sovereign Marshal',
  'Luxe Knife',
  '',
];

/* For specific weapon skins that the API did not update correctly */
